import React, { ReactElement } from "react"

import "./Button.less"

interface ButtonLinkProps {
  href: string
  external?: boolean
  type?: "primary" | "secondary" | "tertiary"
  className?: string
  children?: React.ReactNode
}

/**
 * A link that looks like a button
 */
export default function ButtonLink({
  href,
  external,
  type,
  className,
  children,
}: ButtonLinkProps): ReactElement | null {
  let externalProps = {}
  if (external) {
    externalProps = {
      target: "_blank",
      rel: "noreferrer",
    }
  }

  let typeClassName = "primary-btn"
  switch (type) {
    case "primary":
      typeClassName = "primary-btn"
      break
    case "secondary":
      typeClassName = "secondary-btn"
      break
    case "tertiary":
      typeClassName = "tertiary-btn"
      break
    default:
      break
  }

  return (
    <a
      href={href}
      {...externalProps}
      className={`btn-link ant-btn OneschemaButton ${typeClassName} ${className}`}
    >
      {children}
    </a>
  )
}

import { QueryClientProvider } from "@tanstack/react-query"
import notification, { IconType } from "antd/es/notification"
import React, { ReactElement, useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import { queryClient } from "~/shared/api/client"
import ProtectedRoute from "~/shared/components/frame/ProtectedRoute"
import ConfigProvider from "~/shared/containers/ConfigProvider"
import { identify } from "~/shared/util/analytics"
import viewManager from "~/shared/util/viewManager"

const LoginPage = React.lazy(
  () => import(/* webpackChunkName: "LoginPage" */ "~/shared/components/login/LoginPage"),
)
const SignupPage = React.lazy(
  () =>
    import(/* webpackChunkName: "SignupPage" */ "~/shared/components/login/SignupPage"),
)
const SelectOrgPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SelectOrgPage" */ "~/shared/components/login/SelectOrgPage"
    ),
)
const VerifyMagicLinkPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "VerifyMagicLinkPage" */ "~/shared/components/login/VerifyMagicLinkPage"
    ),
)
const LeadDetailsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LeadDetailsPage" */ "~/shared/components/login/LeadDetailsPage"
    ),
)
const ImporterLauncherErrorPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ImporterLauncherErrorPage" */ "~/shared/components/importer/launcher/ImporterLauncherErrorPage"
    ),
)
const ImporterLauncherPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ImporterLauncherPage" */ "~/shared/components/importer/launcher/ImporterLauncherPage"
    ),
)
const Frame = React.lazy(
  () => import(/* webpackChunkName: "Frame" */ "~/shared/components/frame/Frame"),
)
const FileFeedsEmbedTransformsLauncher = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FileFeedsEmbedTransformsLauncher" */ "~/shared/components/fileFeeds/embedded/FileFeedsEmbedTransformsLauncher"
    ),
)

const openNotificationWithIcon = (type: IconType, message: string) => {
  notification[type]({ message })
}

// Supports routing between the main frame and various widget pages
export default function BaseRouter(): ReactElement | null {
  const org = viewManager.get("org")
  const user = viewManager.get("user")
  useEffect(
    () => {
      const flash = viewManager.get("flash")
      if (flash) {
        openNotificationWithIcon(flash.type, flash.text)
      }

      if (org && user) {
        identify(org, user)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  // If you add a route here, you'll have to manually add a
  // handler in controllers/main.rb.
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/login/*" element={<LoginPage />} />
          <Route path="/signup/*" element={<SignupPage />} />
          <Route
            path="/introduce-yourself/*"
            element={<LeadDetailsPage user={user} org={org} />}
          />
          <Route path="/select-organization/*" element={<SelectOrgPage />} />
          <Route path="/verify-magic-link/*" element={<VerifyMagicLinkPage />} />
          <Route path="/embed-launcher/*" element={<ImporterLauncherPage />} />
          {/**
           * customizations won't exist for this page because there is no org
           * but components can rely on having the provider available.
           * Eventually this might/should have the same entry point as `/embed-launcher`
           * and that place should do the provider wrapping for both instead
           *  */}
          <Route
            path="/embed-launcher-error/*"
            element={
              <ConfigProvider>
                <ImporterLauncherErrorPage />
              </ConfigProvider>
            }
          />
          <Route
            path="/file-feeds-embed/launcher/*"
            element={<FileFeedsEmbedTransformsLauncher />}
          />
          <Route path="*" element={<ProtectedRoute />}>
            <Route path="*" element={<Frame />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

import "@ag-grid-community/styles/ag-grid.css"
import "@ag-grid-community/styles/ag-theme-alpine.css"
import { loader as monacoLoader } from "@monaco-editor/react"
import * as Sentry from "@sentry/react"
import "antd/dist/antd.variable.min.css"
import i18n from "i18next"
import i18nBrowserLanguageDetector from "i18next-browser-languagedetector"
import i18nHttpBackend from "i18next-http-backend"
import i18nIcu from "i18next-icu"
import * as React from "react"
import { createRoot } from "react-dom/client"
import { initReactI18next } from "react-i18next"
import { Provider } from "react-redux"

import BaseRouter from "~/shared/components/BaseRouter"
import ThemeProvider from "~/shared/containers/ThemeProvider"
import store from "~/shared/redux/store"
import "~/shared/styles/global.css"
import "~/shared/styles/root.less"
import { isEmbed } from "~/shared/util/embed"
import { ROOT_HOST, ViewData } from "~/shared/util/viewManager"

import { registerAgGrid } from "./util/agGrid"
import { initSentry } from "./util/sentry"

void registerAgGrid()

// Set monaco's editor version to latest
monacoLoader.config({
  paths: {
    vs: "https://fastly.jsdelivr.net/npm/monaco-editor@0.39.0/min/vs",
  },
})

if (process.env.NODE_ENV === "production") {
  initSentry(ROOT_HOST, process.env.GIT_SHA)
}

const detector = new i18nBrowserLanguageDetector(null, { caches: [] })

// TODO: Verify whether we need to await on i18n.init() somehow.
void i18n
  .use(detector)
  .use(i18nIcu)
  .use(i18nHttpBackend)
  .use(initReactI18next)
  .init({
    lng:
      // only localize embed (setting undefined will use auto-detector)
      isEmbed() && (window.viewData as ViewData | undefined)?.features?.I18N
        ? /* auto detect */ undefined
        : "en",
    returnNull: false,
    fallbackLng: "en",
    load: "all", // enable region-based loading, e.g. pt-BR
    parseMissingKeyHandler: (key) => {
      // These strings are from converted enum from Ruby land that
      // we've decided not to translate
      const expectedErrors = [
        "Country",
        "Countries, dependent territories, and other areas",
        "US States or Territories",
        "US State or Territory",
      ]
      if (!expectedErrors.includes(key)) {
        Sentry.captureMessage(
          `Missing translation key: "${key}" for language ${i18n.language}`,
        )
      }
      return key
    },
    debug: process.env.NODE_ENV !== "production",
    saveMissing: false,
    backend: {
      loadPath: `${process.env.ASSET_PATH}locales/{{lng}}/{{ns}}.${process.env.I18N_HASH}.json`,
      addPath: undefined,
    },
    keySeparator: ".",
    interpolation: {
      // react already safe from xss => https://www.i18next.com/translation-function/interpolation#unescape
      escapeValue: false,
    },
    i18nFormat: {
      bindI18nStore: "added",
    },
    react: {
      bindI18nStore: "added",
    },
  })

const root = createRoot(document.getElementById("root")!)
root.render(
  <React.Suspense fallback={null}>
    <ThemeProvider parent={document.body}>
      <Provider store={store}>
        <BaseRouter />
      </Provider>
    </ThemeProvider>
  </React.Suspense>,
)
